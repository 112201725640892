import React, { useState, useEffect } from 'react'
import {
    SelectInput,
    TextInput,
    required,
    TabbedForm,
    FormTab,
    AutocompleteInput,
    DisabledInput,
    GET_LIST,
    fetchStart,
    fetchEnd,
    FormDataConsumer,
    ReferenceInput
} from 'react-admin'
import {
    companyEmployeeModel as ce,
    companyModel as cm,
    personModel as pm
} from '../../api/models'
import { withStyles } from '@material-ui/styles'
import { ToolbarGeneral } from '../../layout'
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons/'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers'
import { Resources } from '../../api/api'
import { getFio } from '../../utils/dataHelpers'
import { SelectAndSearchInput, AtLeastOneInput } from '../fields'
import Create from '../../layout/create'
import Edit from '../../layout/edit'
import oDataProvider from '../../api/oDataProvider'

const styles = {
    warningCompany: {
        width: '181.5%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    warningContact: {
        width: '180%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    inputs: {
        minWidth: 252
    }
}

const validate = (values) => {
    const errors: any = {}
    return errors
}

const CompanyInputLabel = (props) => {
    return (
        <div className={props.style.warningCompany}>
            <div>Нет компаний *</div>
            <div>
                <ArrowDropDownIcon />
            </div>
        </div>
    )
}

const personFilter = (person) =>
    `(${pm.companiesEmployee}/all(x: x/${ce.id} eq 0) and ${pm.user} ne null)${
        !!person ? ` or ${pm.id} eq ${person.id}` : ''
    }`

const PersonInput = (props) => {
    const { atLeastOne } = props
    return (
        <AtLeastOneInput
            label="Нет контактов"
            className={props.classes.inputs}
            atLeastOne={atLeastOne}
        >
            <SelectAndSearchInput
                label="Контакт"
                source={ce.personId}
                reference={Resources.Person}
                optionRenderer={getFio}
                validate={required()}
                filter={{
                    customFilter: personFilter(props.record.person)
                }}
            />
        </AtLeastOneInput>
    )
}

const DataInput = (props) => {
    const {
        atLeastOne,
        label,
        source,
        reference,
        optionText,
        disableLabel
    } = props
    return atLeastOne ? (
        <SelectAndSearchInput
            label={label}
            source={source}
            reference={reference}
            optionText={optionText}
            validate={required()}
            alwaysOn={true}
            multi={false}
        />
    ) : (
        <DisabledInput className={props.classes.inputs} label={disableLabel} />
    )
}

const EmployeeFormBase = (props: any) => {
    const { isEditToolbar, setTitle, ...rest } = props
    const [state, setState] = useState({
        atLeastOneCompany: false,
        atLeastOnePerson: false
    })

    if (isEditToolbar) {
        const titles: Array<any> = []
        titles.push({
            type: 'text',
            value: 'Контакты'
        })
        titles.push({
            type: 'link',
            href: '/CompanyEmployee',
            value: 'Сотрудники'
        })
        titles.push({
            type: 'text',
            value: `Редактирование сотрудника №${props.record.id} - ${getFio(
                props.record.person
            )}`
        })
        setTitle(titles)
    }

    useEffect(() => {
        const loader = async () => {
            fetchStart()
            const action = GET_LIST
            const { data: company } = await oDataProvider(
                action,
                Resources.Company,
                {
                    pagination: { page: 1, perPage: 1 }
                }
            )
            const { data: person } = await oDataProvider(
                action,
                Resources.Person,
                {
                    pagination: { page: 1, perPage: 1 },
                    filter: {
                        customFilter: personFilter(props.record.person)
                    }
                }
            )
            setState({
                atLeastOneCompany: company.length > 0,
                atLeastOnePerson: person.length > 0
            })
            debugger
        }
        loader()
    }, [])

    return (
        <TabbedForm
            toolbar={
                <ToolbarGeneral
                    isEditToolbar={isEditToolbar}
                    shouldParse
                    disabled={
                        !state.atLeastOneCompany || !state.atLeastOnePerson
                    }
                    {...rest}
                />
            }
            validate={validate}
            {...rest}
        >
            <FormTab label="Основная информация">
                <DataInput
                    atLeastOne={state.atLeastOneCompany}
                    label="Компания"
                    source={ce.companyId}
                    reference={Resources.Company}
                    optionText={cm.shortName}
                    disableLabel={<CompanyInputLabel style={props.classes} />}
                    {...rest}
                />
                <PersonInput
                    classes={props.classes}
                    atLeastOne={state.atLeastOnePerson || !!props.record.person}
                />
                <TextInput
                    label="Email"
                    source={ce.email}
                    validate={required()}
                />
                <TextInput
                    label="Телефон"
                    source={ce.phone}
                    validate={required()}
                />
                <TextInput
                    label="Должность"
                    source={ce.jobPosition}
                    validate={required()}
                />
            </FormTab>
            {/* {rest.id && (
                <FormTab
                    label="Дополнительная Информация"
                    path="info"
                ></FormTab>
            )} */}
        </TabbedForm>
    )
}
export const EmployeeForm = withStyles(styles as any)(EmployeeFormBase)

const EmployeeCreateViewBase = (props: any) => {
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Контакты'
    })
    titles.push({
        type: 'link',
        href: '/CompanyEmployee',
        value: 'Сотрудники'
    })
    titles.push({
        type: 'text',
        value: 'Добавление сотрудника'
    })
    setTitle(titles)

    return (
        <Create {...rest}>
            <EmployeeForm isEditToolbar={false} {...rest} />
        </Create>
    )
}
const enchanceCreate = connect(undefined, {
    setTitle: titleAction
})
export const EmployeeCreateView = enchanceCreate(EmployeeCreateViewBase)

const EmployeeEditViewBase = (props: any) => {
    const { ...rest } = props

    return (
        <Edit {...rest}>
            <EmployeeForm isEditToolbar {...props} />
        </Edit>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const EmployeeEditView = enchanceEdit(EmployeeEditViewBase)
