import React, { useState } from 'react'
import { Toolbar, SaveButton, DeleteButton } from 'react-admin'
import { CheckBoxButton } from '../../components/fields'
import { connect } from 'react-redux'
import { saveWithNoteUpdate } from '../../utils/dataHelpers'
import { makeStyles } from '@material-ui/styles'
import handleValueBeforeSubmit from '../../utils/handleValueBeforeSubmit'

const useStyles = makeStyles({
    dBET: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    ToolbarCreate: {
        backgroundColor: 'white'
    }
})

const ToolbarEditBase = (props) => {
    const {
        basePath,
        handleSubmit,
        saveWithNoteUpdate,
        resource,
        shouldParse,
        showCheckBox,
        id
    } = props
    const [checked, setChecked] = useState(false)
    const classes = useStyles()

    const handleBeforeSubmit = () =>
        handleSubmit((values) => {
            if (!shouldParse) {
                saveWithNoteUpdate({ ...values }, basePath, resource, id)
            } else {
                if (!!showCheckBox) values.checked = checked
                saveWithNoteUpdate(
                    handleValueBeforeSubmit({ ...values }, resource),
                    basePath,
                    resource,
                    id
                )
            }
        })

    return (
        <Toolbar {...props} className={classes.dBET}>
            <div>
                <SaveButton
                    handleSubmitWithRedirect={handleBeforeSubmit}
                    resource={resource}
                    basePath={basePath}
                    size="small"
                    redirect="list"
                    variant="flat"
                />
                {!!showCheckBox && (
                    <CheckBoxButton
                        text="Создать клиента"
                        isSelected={checked}
                        onHandleChange={() => {
                            setChecked(!checked)
                        }}
                    />
                )}
            </div>
            <DeleteButton size="small" redirect="list" variant="text" />
        </Toolbar>
    )
}
export default connect(undefined, { saveWithNoteUpdate })(ToolbarEditBase)
