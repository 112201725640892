import {
    personModel,
    projectModel,
    userRoleModel,
    appUserModel,
    companyModel,
    customerModel,
    userFavoriteIgnoreFilterModel,
    projectParticipantsModel,
    requestModel,
    companyEmployeeModel,
    projectTaskModel,
    taskAssigneeModel,
    letterOfAuthorityModel,
    versionedFileAttachmentModel,
    projectCourtCaseModel,
    commentModel,
    personPassportModel,
    paymentModel,
    fileAttachmentModel,
    invoiceModel
} from './models/'
import { Resources } from '../api/api'
import { FilterClause, Tso } from '../utils/ts-odata'
import { requestClassificationModel } from './models/request/requestClassification'
import hideFavoriteFilter from './hideFavoriteFilter'
import dateFilter from './dateFilter'

export const ROOT_URL: string = (window as any)['apiRootUrl'] || ''
export const AUTH_URL: string = (window as any)['authServer'] || ''
export const API_URL = '/odata'
export const rpValues = {
    customFilter: '#customFilter#:',
    value: '#value#'
}

interface IModifires {
    select: Array<string>
    filter: string
    expand: string
}

interface ICustom {
    customFilter?: string
    customExpand?: string
    customSelect?: Array<string>
    ignoreDefaultFilter?: string
    ignoreDefaultExpand?: string
    ignoreDefaultSelect?: Array<string>
}

const parseParams = (params) => {
    if (!!params.filter) {
        const val: ICustom = {
            customFilter: params.filter.customFilter,
            customExpand: params.filter.customExpand,
            customSelect: params.filter.customSelect,
            ignoreDefaultFilter: params.filter.ignoreDefaultFilter,
            ignoreDefaultExpand: params.filter.ignoreDefaultExpand,
            ignoreDefaultSelect: params.filter.ignoreDefaultSelect
        }
        delete params.filter.customFilter
        delete params.filter.customExpand
        delete params.filter.customSelect
        delete params.filter.ignoreDefaultFilter
        delete params.filter.ignoreDefaultExpand
        delete params.filter.ignoreDefaultSelect

        return { custom: { ...val }, params: { ...params } }
    }

    const val: ICustom = {
        customFilter: undefined,
        ignoreDefaultFilter: undefined,
        customExpand: undefined,
        ignoreDefaultExpand: undefined,
        customSelect: undefined,
        ignoreDefaultSelect: undefined
    }
    return { custom: { ...val }, params: { ...params } }
}

const removeUseless = (params) => {
    if (!!params.filter) {
        if (!!params.filter.isHide) delete params.filter.isHide
        if (!!params.filter.isFavorite) delete params.filter.isFavorite
        if (!!params.filter.endedState) delete params.filter.endedState
        if (!!params.filter.isIgnoreFilters)
            delete params.filter.isIgnoreFilters
        if (!!params.filter.showDeleted) delete params.filter.showDeleted
    }
}

const removeProp = (params, prop: string): boolean => delete params.filter[prop]

const parseFunc = (fun: string): string => {
    const open = fun.indexOf('{')
    const close = fun.lastIndexOf('}')

    return fun.substring(open + 1, close - 1)
}
const applyUnusualFilters = (odataQuery: Tso, params): void => {
    for (const prop in params.filter) {
        if (prop.includes('$date')) {
            dateFilter(odataQuery, params.filter[prop], prop.substring(1))
            removeProp(params, prop)
        } else if (prop.includes(rpValues.value)) {
            odataQuery.filter(
                new FilterClause(
                    prop.replaceAll(rpValues.value, params.filter[prop])
                )
            )
            removeProp(params, prop)
        } else if (prop.includes(rpValues.customFilter)) {
            odataQuery.filter(
                new FilterClause(prop.replace(rpValues.customFilter, ''))
            )
            removeProp(params, prop)
        } else if (prop.startsWith('function')) {
            const fun = parseFunc(prop)

            const filter = new Function('data', fun)(params.filter[prop])
            if (!!filter) odataQuery.filter(new FilterClause(filter))
            removeProp(params, prop)
        }
    }
}

const applyFilters = (
    odataQuery: Tso,
    modifires: IModifires,
    custom: ICustom,
    isIgnoreFilters: boolean
): void => {
    if (!!custom.customExpand && !!custom.ignoreDefaultExpand)
        modifires.expand = custom.customExpand
    else if (!!custom.customExpand) modifires.expand += custom.customExpand
    else if (!!custom.ignoreDefaultExpand) modifires.expand = ''

    if (!!custom.customFilter && !!custom.ignoreDefaultFilter)
        modifires.filter = custom.customFilter
    else if (!!custom.customFilter) modifires.filter += custom.customFilter
    else if (!!custom.ignoreDefaultFilter) modifires.filter = ''

    if (!!custom.customSelect && !!custom.ignoreDefaultSelect)
        modifires.select = custom.customSelect
    else if (!!custom.customSelect) {
        if (modifires.select !== null)
            modifires.select = modifires.select.concat(custom.customSelect)
        else modifires.select = custom.customSelect
    } else if (!!custom.ignoreDefaultSelect) modifires.select = []

    if (!!modifires.filter && !isIgnoreFilters)
        odataQuery.filter(new FilterClause(modifires.filter))
    if (!!modifires.select.length) odataQuery.select(modifires.select)
    if (!!modifires.expand) odataQuery.expand(modifires.expand)
}

export default (odataQuery: Tso, resource: Resources, parameters): void => {
    const { custom, params } = parseParams(parameters)
    const isIgnoreFilters =
        !!params.filter && !!params.filter.isIgnoreFilters
            ? params.filter.isIgnoreFilters
            : false
    if (!isIgnoreFilters)
        hideFavoriteFilter(odataQuery, params.filter, resource)

    removeUseless(params)

    const userId = localStorage.getItem('id')
    const modifires: IModifires = { select: [], filter: '', expand: '' }

    switch (resource) {
        case Resources.AppUser: {
            const pm = personModel,
                au = appUserModel
            const personSelect = `$select=${pm.id},${pm.patronymicName},${pm.firstName},${pm.lastName},${pm.phone},${pm.email},${pm.contactInformation}`
            const expand =
                `${au.person},` + // (${personSelect})
                `${au.userRoles}($select=${userRoleModel.roleId})`
            modifires.expand = expand

            const select = [
                au.userName,
                au.email,
                au.id,
                au.lockoutEnabled,
                au.emailConfirmed
            ]
            modifires.select = select
            break
        }
        case Resources.Project: {
            const pm = personModel,
                cm = companyModel,
                pr = projectModel,
                ct = customerModel
            if (!!params.data && !!params.data.findCustomerClient) {
                const personSelect = `$select=${pm.id},${pm.firstName},${pm.lastName},${pm.patronymicName}`
                const companySelect = `$select=${cm.id},${cm.fullName},${cm.shortName}`
                const expand =
                    `${pr.customer}($expand=${ct.person}(${personSelect}),${ct.company}(${companySelect})),` +
                    `${pr.lettersOfAuthority},${pr.versionedFileAttachment},` +
                    `${pr.userFilters}($filter=${userFavoriteIgnoreFilterModel.userId} eq ${userId}),` +
                    `${pr.projectParticipants}`
                modifires.expand = expand
                break
            }

            if (!!params.filter && params.filter.ignoreExpand === true) {
                delete params.filter.ignoreExpand
            } else {
                const personSelect = `$select=${pm.id},${pm.firstName},${pm.lastName},${pm.patronymicName}`
                const companySelect = `$select=${cm.id},${cm.fullName},${cm.shortName}`
                const expand =
                    `${pr.customer}($expand=${ct.person}(${personSelect}),${ct.company}(${companySelect})),` +
                    `${pr.lettersOfAuthority},` +
                    `${pr.versionedFileAttachment},` +
                    `${pr.userFilters}($filter=${userFavoriteIgnoreFilterModel.userId} eq ${userId}),` +
                    `${pr.projectParticipants}`
                modifires.expand = expand
                break
            }
        }

        case Resources.Payment: {
            const py = paymentModel,
                iv = invoiceModel
            const expandInvoice = `${py.invoice}($select=${iv.title})`
            modifires.expand = expandInvoice
            break
        }

        case Resources.Customer: {
            const pm = personModel,
                cm = companyModel,
                ct = customerModel
            const personSelect = `$select=${cm.id},${pm.firstName},${pm.lastName},${pm.patronymicName}`
            const companySelect = `$select=${cm.id},${cm.fullName},${cm.inn},${cm.kpp},${cm.comments},${cm.shortName}`
            const expand = `${ct.person}(${personSelect}),${ct.company}(${companySelect})`
            modifires.expand = expand
            break
        }

        case Resources.ProjectParticipant: {
            const pm = personModel
            const personSelect = `${pm.id},${pm.firstName},${pm.lastName},${pm.patronymicName}`
            const expand = `${projectParticipantsModel.appUser}($expand=${appUserModel.person}($select=${personSelect}))`
            modifires.expand = expand
            break
        }

        case Resources.Request: {
            const rc = requestClassificationModel,
                ff = userFavoriteIgnoreFilterModel,
                rm = requestModel
            const favorSelect = `$select=${ff.isFavorite},${ff.isHide},${ff.id}`
            const classificationSelect =
                `$select=` +
                `${rc.type},${rc.requestId},${rc.isSubsidiaryLiability},` +
                `${rc.bankruptcyVariant},${rc.executiveProceedingVariant},` +
                `${rc.negotiationsAssistanceVariant},${rc.anotherVariant},${rc.id}`
            const expand =
                `${rm.userFilters}($filter=${ff.userId} eq ${userId};${favorSelect}),` +
                `${rm.classification}(${classificationSelect})`
            modifires.expand = expand

            const select = [
                rm.subject,
                rm.text,
                rm.customerId,
                rm.classification,
                rm.id,
                rm.createdOn,
                rm.status,
                rm.userFilters,
                rm.commentListId,
                rm.projectId
            ]
            modifires.select = select
            break
        }

        case Resources.CompanyEmployee: {
            const pm = personModel,
                cm = companyEmployeeModel
            const personSelect = `${pm.user},${pm.firstName},${pm.lastName},${pm.patronymicName},${pm.id}`
            const expand = `${cm.person}($expand=${pm.user}($select=${appUserModel.id});$select=${personSelect})`
            modifires.expand = expand
            break
        }

        case Resources.Person: {
            const pm = personModel,
                au = appUserModel,
                ps = personPassportModel
            const selectPassports =
                `$select=${ps.id},${ps.batch},${ps.birthDay},${ps.birthPlace},${ps.createdOn},${ps.issuedAt},` +
                `${ps.issuer},${ps.issuerNum},${ps.regPlace},${ps.serialNumber},${ps.sex}`
            const expand =
                `${pm.userFilters}($filter=${userFavoriteIgnoreFilterModel.userId} eq ${userId}),` +
                `${pm.passports}(${selectPassports}),` +
                `${pm.user}($expand=${au.userRoles}($select=${userRoleModel.roleId});$select=${au.userRoles})`
            modifires.expand = expand

            const select = [
                pm.id,
                pm.firstName,
                pm.lastName,
                pm.patronymicName,
                pm.phone,
                pm.email,
                pm.userId
            ]
            // modifires.select = select
            break
        }

        case Resources.ProjectTask: {
            const pt = projectTaskModel
            const expand =
                `${pt.taskAssignees}($expand=${taskAssigneeModel.user}),` +
                `${pt.project}($select=${projectModel.color}),` +
                `${pt.userFilters}($filter=${userFavoriteIgnoreFilterModel.userId} eq ${userId})`
            modifires.expand = expand
            break
        }

        case Resources.TaskAssignee: {
            const pm = personModel
            const personSelect = `${pm.id},${pm.firstName},${pm.lastName},${pm.patronymicName}`
            const expand = `${taskAssigneeModel.user}($expand=${appUserModel.person}($select=${personSelect}))`
            modifires.expand = expand
            break
        }

        case Resources.LetterOfAuthority: {
            const expand = `${letterOfAuthorityModel.project}($expand=${projectModel.projectParticipants})`
            modifires.expand = expand
            break
        }

        case Resources.VersionedFileAttachment: {
            const vm = versionedFileAttachmentModel
            const fa = fileAttachmentModel
            const filesSelect =
                `$select=` +
                `${fa.name},${fa.createdBy},${fa.createdOn},${fa.extension},${fa.internalGuid},` +
                `${fa.externalUrl},${fa.googleDriveExternalToken},${fa.googleDriveInternalToken},${fa.id}`
            const expand =
                `${vm.files}($orderBy=${vm.id} desc;${filesSelect}),` +
                `${vm.userFilters}($filter=${userFavoriteIgnoreFilterModel.userId} eq ${userId}),` +
                `${vm.ownerRequest},` +
                `${vm.ownerProject}($expand=${projectModel.projectParticipants},${projectModel.customer})`
            modifires.expand = expand

            const select = [
                vm.atDate,
                vm.commentListId,
                vm.createdById,
                vm.createdOn,
                vm.description,
                vm.editAccessFlags,
                vm.id,
                vm.name,
                vm.ownerProjectId,
                vm.ownerRequestId,
                vm.ownerUserId,
                vm.visibilityFlags
            ]
            modifires.select = select
            break
        }
        case Resources.ProjectCourtCase: {
            const pc = projectCourtCaseModel
            const expand = `${pc.courtHearings},${pc.caseInstances},${pc.projectCourtCaseParticipant}`
            modifires.expand = expand
            break
        }

        case Resources.Comment: {
            const cm = commentModel,
                pm = personModel,
                au = appUserModel
            const selectPerson = `$expand=${au.person}($select=${pm.firstName},${pm.lastName},${pm.patronymicName})`
            const expand =
                `${cm.createdBy}(${selectPerson}),` +
                `${cm.childrenComments}($expand=${cm.createdBy}(${selectPerson}))`
            modifires.expand = expand
            break
        }
    }

    applyUnusualFilters(odataQuery, params)
    applyFilters(odataQuery, modifires, custom, isIgnoreFilters)
}
