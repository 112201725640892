import * as React from 'react'
import { Route } from 'react-router-dom'
import { CalendarView } from '../calendar/calendarView'
import { Callback } from '../auth/authCallback'
import { RequestAssignPage } from '../requests/requestAssignPage'

export default [
    <Route exact={true} key={1} path="/calendar" component={CalendarView} />,
    <Route exact={true} key={2} path="/auth/callback" component={Callback} />,
    <Route exact={true} key={3} path="/callback.html" component={Callback} />,

    <Route
        exact={true}
        key={4}
        path="/Request/:id/assign"
        component={RequestAssignPage}
    />
]
