import React, { useState } from 'react'
import {
    Toolbar,
    showNotification,
    CREATE,
    UPDATE,
    DeleteButton
} from 'react-admin'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Button, Typography } from '@material-ui/core'
import { Resources } from '../../api/api'
import { Save as SaveIcon } from '@material-ui/icons'
import CircularProgress from '@material-ui/core/CircularProgress'
import oDataProvider from '../../api/oDataProvider'

const useStyles = makeStyles({
    dBET: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    ToolbarCreate: {
        backgroundColor: 'white'
    }
})

// Очень странный компонент. Работает на магии. Не на магии не работает. Не трогать
const ToolbarProjectTaskBase = (props) => {
    const classes = useStyles(``)
    const {
        handleSubmit,
        showNotification,
        isEditToolbar,
        ...rest
    } = JSON.parse(JSON.stringify(props))

    const [saving, setSaving] = useState(false)
    const handleBeforeSubmit = () => props.handleSubmit((values) => values)

    const CatchingSaveButton = (saveProps) => {
        const { invalid } = saveProps
        const Icon = () => <SaveIcon fontSize="small" color="primary" />
        const Label = () => (
            <Typography variant="button" color="primary">
                Сохранить
            </Typography>
        )
        const onHandle = async (e) => {
            if (invalid) {
                props.showNotification('ra.message.invalid_form', 'warning')
                saveProps.handleSubmitWithRedirect('list')()
                return
            }
            const {
                procedure,
                createNewProcedure,
                procedureId,

                createNewPhase,
                phase,
                phaseId,

                ...rest
            } = handleBeforeSubmit()()
            if (!rest.assignees.length) {
                props.showNotification(
                    'ra.validation.requiredField',
                    'warning',
                    {
                        messageArgs: { field: 'Исполнители' }
                    }
                )
                return
            }

            setSaving(true)

            let procId = procedureId
            if (!!createNewProcedure && !!createNewProcedure.length) {
                const { data: proc } = await oDataProvider(
                    CREATE,
                    Resources.Procedure,
                    { data: { projectId: rest.projectId, ...procedure } }
                )
                procId = proc.id
            }
            let phasId = phaseId
            if (!!createNewPhase && !!createNewPhase.length) {
                const { data: phas } = await oDataProvider(
                    CREATE,
                    Resources.Phase,
                    { data: { projectId: rest.projectId, ...phase } }
                )
                phasId = phas.id
            }

            try {
                if (props.isEditToolbar) {
                    await oDataProvider(UPDATE, Resources.ProjectTask, {
                        id: rest.id,
                        data: {
                            title: rest.title,
                            description: rest.description,
                            startDate: rest.startDate,
                            endDate: rest.endDate,
                            priorityLevel: rest.priorityLevel,
                            assignSelf: rest.assignSelf,
                            isCompleted: rest.isCompleted,
                            procedureId: procId,
                            phaseId: phasId
                        }
                    })
                } else {
                    await oDataProvider(CREATE, Resources.ProjectTask, {
                        data: { ...rest, procedureId: procId, phaseId: phasId }
                    })
                }
                props.history.push(`/${Resources.ProjectTask}`)
            } catch (e) {
                if (
                    !!e.body &&
                    !!e.body.error &&
                    !!e.body.error.details.length &&
                    !!e.body.error.details[0].message
                )
                    props.showNotification(`ra.raw`, 'warning', {
                        messageArgs: { text: e.body.error.details[0].message }
                    })
            }

            setSaving(false)
        }

        return (
            <Button variant="text" size="small" onClick={onHandle}>
                <Icon /> &nbsp;
                <Label />
            </Button>
        )
    }

    return (
        <Toolbar
            {...rest}
            className={classes.ToolbarCreate}
            style={
                props.isEditToolbar
                    ? { display: 'flex', justifyContent: 'space-between' }
                    : undefined
            }
        >
            <div>
                {!saving && <CatchingSaveButton {...rest} />}
                {saving && (
                    <CircularProgress size={18} thickness={2} {...rest} />
                )}
            </div>
            {props.isEditToolbar && <DeleteButton />}
        </Toolbar>
    )
}
export const ToolbarProjectTask = connect(undefined, { showNotification })(
    ToolbarProjectTaskBase
)
