import * as React from 'react'
import { DefaultFormActions } from '../../layout/bar'
import { Paper, Typography } from '@material-ui/core'
import { TextField, Show, SimpleShowLayout, CREATE } from 'react-admin'
import { connect } from 'react-redux'
import { Resources } from '../../api/api'
import { titleAction } from '../../reducers/titleReducer'
import oDataProvider from '../../api/oDataProvider'

const getValidDate = (date: string): string => {
    const dData = new Date(date)
    const res =
        dData.getFullYear() +
        '.' +
        dData.getMonth() +
        '.' +
        dData.getDay() +
        ' ' +
        dData.getHours() +
        ':' +
        dData.getMinutes() +
        ':' +
        dData.getSeconds()
    return res
}

const ShowNotificationForm = (props) => {
    const { record } = props
    const [isFetched, setIsFetched] = React.useState(false)
    const date = getValidDate(record.createdOn)

    if (!record.isRead && !isFetched) {
        oDataProvider(CREATE, Resources.Notification_Read, {
            data: { index: record.id }
        })
        setIsFetched(true)
    }
    return (
        <SimpleShowLayout toolbar={null} {...props}>
            <Typography style={{ paddingBottom: 10 }} variant="caption">
                Время отправки
            </Typography>
            <Typography style={{ paddingBottom: 12 }}>{date}</Typography>
            <TextField source="concreteNotification.title" label="Тема" />
        </SimpleShowLayout>
    )
}

export const NotificationShowBase = (props) => {
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'link',
        href: '/Notification',
        value: 'Сообщения'
    })
    titles.push({
        type: 'text',
        value: 'Карта сообщения'
    })
    setTitle(titles)
    return (
        <Show actions={<DefaultFormActions />} undoable {...rest}>
            <ShowNotificationForm {...rest} />
        </Show>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const NotificationShow = enchanceEdit(NotificationShowBase)
