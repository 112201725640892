import * as React from 'react'
import { Datagrid, Filter, List, TextField, TextInput } from 'react-admin'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'
import { PersonFavoriteListActions } from '../../layout/favoriteHidenToobars'
import { AddToFavorite } from '../fields'
import { personModel } from '../../api/models'

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    }
})

const ContactsFilter = (props: any) => (
    <Filter {...props}>
        <TextInput source="$contains(firstName)" label="Имя" alwaysOn={true} />
        <TextInput
            source="$contains(lastName)"
            label="Фамилия"
            alwaysOn={true}
        />
        <TextInput source="$contains(patronymicName)" label="Отчество" />
        <TextInput
            source="$contains(email)"
            label="Эл. почта"
            alwaysOn={true}
        />
        <TextInput source="$contains(phone)" label="Телефон" />
    </Filter>
)

const ContactListBase = (props: any) => {
    const classes = useStyles()
    const { setTitle, isFavorite, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Контакты'
    })
    titles.push({
        type: 'text',
        value: 'Люди'
    })
    setTitle(titles)

    const filter: any = {}
    if (isFavorite) filter.isFavorite = true

    return (
        <List
            {...rest}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<ContactsFilter />}
            filter={filter}
            actions={<PersonFavoriteListActions />}
            exporter={false}
        >
            <Datagrid rowClick="edit" classes={{ rowEven: classes.evenRow }}>
                <TextField
                    source={personModel.patronymicName}
                    label="Фамилия"
                />
                <TextField source={personModel.firstName} label="Имя" />
                <TextField
                    source={personModel.patronymicName}
                    label="Отчество"
                />
                <TextField source={personModel.email} label="Эл. почта" />
                <TextField source={personModel.phone} label="Телефон" />
                <AddToFavorite />
            </Datagrid>
        </List>
    )
}
const enchanceList = connect(
    (state: any) => ({
        isFavorite: state.isFavoritePerson
    }),
    {
        setTitle: titleAction
    }
)
export const ContactList = enchanceList(ContactListBase)
