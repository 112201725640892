import * as React from 'react'

import Layout from './layout/layout'
import LogoImg from './layout/logoImg'
import dataProvider from './api/oDataProvider'
import routes from './components/navigation/routes'
import Menu from './layout/menu/Menu'
import JssProvider from 'react-jss/lib/JssProvider'
import russianMessages from './utils/ra-language-russian'

import { ProcedureTemplateCreateView, ProcedureTemplateEditView } from './components/templates/tasks/procedureTemplateForm'

import { Admin, Resource } from 'react-admin'
import { catchError } from './layout'
import { UserList } from './components/users/userList'
import { PageList } from './components/pageList/pageList'
import { ContactList } from './components/contactList/contactList'
import { CompanyList } from './components/companyList/companyList'
import { BlogList } from './components/blog/blogList'
import { BlogCategoryList } from './components/blog/blogCategoryList'
import { ProjectList } from './components/project/projectList'
import { LookupList } from './components/lookup/lookupList'
import { LookupValueList } from './components/lookup/lookupValueList'
import { CustomerList } from './components/customer/customerList'
import { ProjectParticipantList } from './components/project/projectParticipantList'
import { PageCreateView, PageEditView } from './components/pageList/pageEdit'
import {
    LookupCreateView,
    LookupEditView
} from './components/lookup/lookupForm'
import {
    LookupValueCreateView,
    LookupValueEditView
} from './components/lookup/lookupValueForm'
import {
    ProjectCreateView,
    ProjectEditView
} from './components/project/projectForm'
import {
    ContactCreateView,
    ContactEditView
} from './components/contactList/contactEdit'
import {
    CustomerCreateView,
    CustomerEditView
} from './components/customer/customerEdit'
import {
    ProjectParticipantCreateView,
    ProjectParticipantEditView
} from './components/project/projectParticipantForm'
import { createGenerateClassName } from '@material-ui/core'
import { BlogCreateView, BlogEditView } from './components/blog/blogEdit'
import {
    BlogCategoryCreateView,
    BlogCategoryEditView
} from './components/blog/blogCategoryEdit'
import { InvoiceList } from './components/invoices/invoiceList'
import {
    InvoiceEditView,
    InvoiceCreateView
} from './components/invoices/invoiceForm'
import { PaymentList } from './components/payments/paymentList'
import {
    PaymentEditView,
    PaymentCreateView
} from './components/payments/paymentForm'
import { TaskTemplateList } from './components/templates/tasks/taskTemplateList'
import { PhaseTemplateList } from './components/templates/tasks/phaseTemplateList'
import { ProcedureTemplateList } from './components/templates/tasks/procedureTemplateList'
import { TaskList } from './components/tasks/taskList'
import {
    CompanyCreateView,
    CompanyEditView
} from './components/companyList/companyEdit'
import { ProjectTemplateList } from './components/templates/projects/projectTemplateList'
import {
    ProjectTemplateCreateView,
    ProjectTemplateEditView
} from './components/templates/projects/projectTemplateForm'
import { Dashboard } from './components/dashboard/dashboard'
import { TaskCreateView, TaskEditView } from './components/tasks/taskForm'
import { EmployeeList } from './components/employees/employeeList'
import {
    EmployeeCreateView,
    EmployeeEditView
} from './components/employees/employeeEdit'
import { RequestList } from './components/requests/requestList'
import {
    RequestCreateView,
    RequestEditView,
    RequestShowView
} from './components/requests/requestForm'
import {
    TaskTemplateCreateView,
    TaskTemplateEditView
} from './components/templates/tasks/taskTemplateForm'
import {
    PhaseTemplateCreateView,
    PhaseTemplateEditView
} from './components/templates/tasks/phaseTemplateForm'
import { PhaseCreateView, PhaseEditView } from './components/tasks/phaseForm'
import {
    ProcedureCreateView,
    ProcedureEditView
} from './components/tasks/procedureForm'
import { AuthProvider } from './components/auth/authProvider'
import { createBrowserHistory } from 'history'
import { LoginPage } from './components/auth/loginPage'
import { LogoutButton } from './components/auth/logoutButton'
import { UserEditView, UserCreate } from './components/users/userForm'
import { addUploadFeature } from './api/addUploadFeature'
import { ProjectShow as ShowProject } from './components/project/projectShow'
import { PersonalFileList } from './components/personalFiles/personalFileList'
import {
    PersonalFileCreateView,
    PersonalFileEditView
} from './components/personalFiles/personalFileForm'
import { checkPermissionsEmployeeAndUp } from './components/auth/authHelpers'
import { CourtCaseList } from './components/courtCase/projectCourtCaseList'
import {
    CourtCaseCreateView,
    CourtCaseEditView
} from './components/courtCase/projectCourtCaseForm'
import { RequestCommandView } from './components/requests/commands/requestCommandForm'
import { NotificationList } from './components/notifications/notificationList'
import { NotificationShow } from './components/notifications/notificationCard'
import { TaskShow } from './components/tasks/taskShow'
import { UserShow } from './components/users/userShow'
import { UserNotification } from './components/notifications/UserNotificationChannel/UserNotification'
import { LettersOfAuthorityList } from './components/lettersOfAuthorityList/lettersOfAuthorityList'
import {
    LettersOfAuthorityShow,
    LettersOfAuthorityCreate,
    LettersOfAuthorityEdit
} from './components/lettersOfAuthorityList/lettersOfAuthorityForm'

import {
    ClassificationDictValueCreate,
    ClassificationDictValueEdit
} from './components/lettersOfAuthorityList/classificationDictValue/classificationDictValueIdForm'
import { ClassificationDictValueIdList } from './components/lettersOfAuthorityList/classificationDictValue/classificationDictValueIdList'

import {
    RequesterDictValueCreate,
    RequesterDictValueEdit
} from './components/lettersOfAuthorityList/requesterDictValue/requesterDictValueForm'
import { RequesterDictValueIdList } from './components/lettersOfAuthorityList/requesterDictValue/requesterDictValueList'

import { Resources } from './api/api'

import {
    themeReducer,
    titleReducer,
    isFavoriteProjectReducer,
    isHiddenProjectReducer,
    endedStateProjectReducer,
    endedStateProjectTaskReducer,
    isFavoritePersonReducer,
    isFavoriteProjectTaskReducer,
    isFavoriteRequestReducer,
    isHiddenProjectTaskReducer,
    isHiddenRequestReducer,
    isIgnoreProjectTaskFiltersReducer,
    isFavoriteFileReducer,
    themeContainReducer,
    isEditedLettersOfAuthorityReducer
} from './reducers'

const history = createBrowserHistory()
const i18nProvider = (locale: string) => russianMessages[locale]

const appClassNameGenerator = createGenerateClassName({
    productionPrefix: 'bsr'
})

const uploadCapableDataProvider = addUploadFeature(dataProvider)

const App = () => (
    <JssProvider generateClassName={appClassNameGenerator}>
        <Admin
            locale="ru"
            i18nProvider={i18nProvider}
            authProvider={AuthProvider}
            appLayout={Layout}
            customReducers={{
                theme: themeReducer,
                themeContain: themeContainReducer,
                title: titleReducer,
                isFavoriteProject: isFavoriteProjectReducer,
                isHiddenProject: isHiddenProjectReducer,
                endedStateProject: endedStateProjectReducer,
                endedStateProjectTask: endedStateProjectTaskReducer,
                isFavoritePerson: isFavoritePersonReducer,
                isFavoriteProjectTask: isFavoriteProjectTaskReducer,
                isFavoriteRequest: isFavoriteRequestReducer,
                isHiddenProjectTask: isHiddenProjectTaskReducer,
                isHiddenRequest: isHiddenRequestReducer,
                isIgnoreProjectTaskFilters: isIgnoreProjectTaskFiltersReducer,
                isFavoriteFile: isFavoriteFileReducer,
                isEditedLettersOfAuthority: isEditedLettersOfAuthorityReducer
            }}
            customRoutes={routes}
            dataProvider={uploadCapableDataProvider}
            history={history}
            menu={Menu}
            dashboard={Dashboard}
            loginPage={LoginPage}
            logoutButton={LogoutButton}
            title={<LogoImg />}
        >
            {(permissions) => [
                <Resource
                    name={Resources.AppUser}
                    list={UserList}
                    edit={UserEditView}
                    show={UserShow}
                    create={UserCreate}
                />,
                <Resource name={Resources.AppRole} />,
                <Resource name={Resources.UserRole} />,

                <Resource name={Resources.CommentList} />,
                <Resource name={Resources.Comment} />,

                <Resource name={Resources.Document} />,
                <Resource name={Resources.Attachment} />,
                <Resource name={Resources.FileAttachment} />,
                <Resource name={Resources.FileMetadata} />,
                <Resource name={Resources.TimelineEvent} />,
                <Resource name={Resources.CalendarEvent} />,
                <Resource name={Resources.PersonPassport} />,

                <Resource
                    name={Resources.LetterOfAuthorityRequesterDictValue}
                    list={RequesterDictValueIdList}
                    create={RequesterDictValueCreate}
                    edit={RequesterDictValueEdit}
                />,
                <Resource
                    name={Resources.LetterOfAuthorityClassificationDictValue}
                    list={ClassificationDictValueIdList}
                    create={ClassificationDictValueCreate}
                    edit={ClassificationDictValueEdit}
                />,

                <Resource
                    name={Resources.LetterOfAuthority}
                    list={LettersOfAuthorityList}
                    create={LettersOfAuthorityCreate}
                    show={LettersOfAuthorityShow}
                    edit={LettersOfAuthorityEdit}
                />,

                <Resource
                    name={Resources.Page}
                    list={PageList}
                    create={PageCreateView}
                    edit={PageEditView}
                />,
                <Resource
                    name={Resources.Blog}
                    list={BlogList}
                    create={BlogCreateView}
                    edit={BlogEditView}
                />,
                <Resource
                    name={Resources.BlogCategory}
                    list={BlogCategoryList}
                    create={BlogCategoryCreateView}
                    edit={BlogCategoryEditView}
                />,
                <Resource
                    name={Resources.Project}
                    list={ProjectList}
                    create={
                        checkPermissionsEmployeeAndUp(permissions)
                            ? ProjectCreateView
                            : null
                    }
                    edit={
                        checkPermissionsEmployeeAndUp(permissions)
                            ? ProjectEditView
                            : null
                    }
                    show={ShowProject}
                />,
                <Resource
                    name={Resources.ProjectParticipant}
                    list={ProjectParticipantList}
                    create={ProjectParticipantCreateView}
                    edit={ProjectParticipantEditView}
                />,
                <Resource
                    name={Resources.ProjectCourtCase}
                    list={CourtCaseList}
                    create={CourtCaseCreateView}
                    edit={CourtCaseEditView}
                />,

                <Resource
                    name={Resources.ProjectTask}
                    list={TaskList}
                    show={TaskShow}
                    create={TaskCreateView}
                    edit={TaskEditView}
                />,
                <Resource name={Resources.TaskAssignee} />,
                <Resource
                    name={Resources.Phase}
                    create={PhaseCreateView}
                    edit={PhaseEditView}
                />,
                <Resource
                    name={Resources.Procedure}
                    create={ProcedureCreateView}
                    edity={ProcedureEditView}
                />,

                <Resource
                    name={Resources.Invoice}
                    list={InvoiceList}
                    create={InvoiceCreateView}
                    edit={InvoiceEditView}
                />,
                <Resource
                    name={Resources.Payment}
                    list={PaymentList}
                    create={PaymentCreateView}
                    edit={PaymentEditView}
                />,
                <Resource
                    name={Resources.Person}
                    list={ContactList}
                    create={ContactCreateView}
                    edit={ContactEditView}
                />,
                <Resource
                    name={Resources.Company}
                    list={CompanyList}
                    create={CompanyCreateView}
                    edit={CompanyEditView}
                />,
                <Resource
                    name={Resources.CompanyEmployee}
                    list={EmployeeList}
                    create={EmployeeCreateView}
                    edit={EmployeeEditView}
                />,
                <Resource
                    name={Resources.Customer}
                    list={CustomerList}
                    create={CustomerCreateView}
                    edit={CustomerEditView}
                />,

                <Resource
                    name={Resources.Lookup}
                    list={LookupList}
                    create={LookupCreateView}
                    edit={LookupEditView}
                />,
                <Resource
                    name={Resources.LookupValue}
                    list={LookupValueList}
                    create={LookupValueCreateView}
                    edit={LookupValueEditView}
                />,

                <Resource
                    name={Resources.ProjectTaskTemplate}
                    list={TaskTemplateList}
                    create={TaskTemplateCreateView}
                    edit={TaskTemplateEditView}
                />,
                <Resource
                    name={Resources.PhaseTemplate}
                    list={PhaseTemplateList}
                    create={PhaseTemplateCreateView}
                    edit={PhaseTemplateEditView}
                />,
                <Resource
                    name={Resources.ProcedureTemplate}
                    list={ProcedureTemplateList}
                    create={ProcedureTemplateCreateView}
                    edit={ProcedureTemplateEditView}
                />,
                <Resource
                    name={Resources.ProjectTemplate}
                    list={ProjectTemplateList}
                    create={ProjectTemplateCreateView}
                    edit={ProjectTemplateEditView}
                />,

                <Resource
                    name={Resources.Request}
                    list={RequestList}
                    create={RequestCreateView}
                    edit={RequestEditView}
                    show={RequestShowView}
                />,

                <Resource
                    name={Resources.VersionedFileAttachment}
                    list={PersonalFileList}
                    create={PersonalFileCreateView}
                    edit={PersonalFileEditView}
                />,
                <Resource
                    name={Resources.RequestCommand}
                    create={RequestCommandView}
                />,

                <Resource
                    name={Resources.Notification}
                    list={NotificationList}
                    show={NotificationShow}
                />,
                <Resource
                    name={Resources.UserNotificationChannel}
                    list={UserNotification}
                />
            ]}
        </Admin>
    </JssProvider>
)
export default catchError(App)
