import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Paper } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import { GET_LIST, GET_ONE, withDataProvider } from 'react-admin'
import { titleAction } from '../../reducers/titleReducer'

import 'react-big-calendar/lib/css/react-big-calendar.css'

import localization from '../../../node_modules/moment/locale/ru'
import Calendar from '../react-big-calendar/src'

const fillTaskEvents = async (events, projectsTask, projects, dataProvider) => {
    for (const task of projectsTask) {
        const projIndex = projects.findIndex(
            (elem) => elem.id === task.projectId
        )
        let project: any = {}
        if (projIndex === -1) {
            const { data: temp } = await dataProvider(GET_ONE, 'Project', {
                id: task.projectId
            })
            project = temp
            projects.push(temp)
        } else {
            project = projects[projIndex]
        }
        events.push({
            color: project.color,
            title: task.title,
            startDate: task.startDate,
            endDate: task.endDate,
            link: `/ProjectTask/${task.id}`
        })
    }
}
const fillHearingEvents = async (
    events,
    projectCourtHearings,
    projectCourtCases,
    projects,
    dataProvider
) => {
    for (const hearing of projectCourtHearings) {
        let courtCase: any = {}
        const projectCourtIndex = projects.findIndex(
            (elem) => elem.id === hearing.projectId
        )
        if (projectCourtIndex === -1) {
            const { data: temp } = await dataProvider(
                GET_ONE,
                'ProjectCourtCase',
                { id: hearing.projectCourtCaseId }
            )
            courtCase = temp
            projectCourtCases.push(temp)
        } else {
            courtCase = projects[courtCase]
        }
        const projIndex = projects.findIndex(
            (elem) => elem.id === courtCase.projectId
        )
        let project: any = {}
        if (projIndex === -1) {
            const { data: temp } = await dataProvider(GET_ONE, 'Project', {
                id: courtCase.projectId
            })
            project = temp
            projects.push(temp)
        } else {
            project = projects[projIndex]
        }
        events.push({
            color: project.color,
            title: `Слушание: "${hearing.summary}"`,
            startDate: hearing.start,
            endDate: hearing.end,
            link: `/Project/${projIndex}/court`
        })
    }
}

moment.locale('ru', localization)
const localizer = Calendar.momentLocalizer(moment)
const calendarMessages = {
    next: 'След.',
    previous: 'Пред.',
    today: 'Сегодня',
    month: 'Месяц',
    week: 'Неделя',
    day: 'День',
    agenda: 'Список'
}

const styles = (theme) =>
    createStyles({
        calendar: {
            '& .rbc-toolbar-label': {
                textTransform: 'capitalize'
            }
        }
    })

const CalendarComponent = (props) => {
    const { dataProvider, classes, setTitle, ...rest } = props

    const [events, setEvents]: any = useState([])
    const [isFetched, setIsFetched] = useState(false)

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Календарь'
    })
    setTitle(titles)

    const fetchData = async () => {
        const { data: projectsTask } = await dataProvider(
            GET_LIST,
            'ProjectTask',
            {
                sort: { field: 'Id', order: 'DESC' },
                pagination: { page: 1, perPage: 100 }
            }
        )
        const events: Array<any> = []
        const projects: Array<any> = []
        await fillTaskEvents(events, projectsTask, projects, dataProvider)

        const { data: projectCourtHearings } = await dataProvider(
            GET_LIST,
            'ProjectCourtHearing',
            {
                sort: { field: 'Id', order: 'DESC' },
                pagination: { page: 1, perPage: 100 }
            }
        )
        const projectCourtCases: Array<any> = []
        await fillHearingEvents(
            events,
            projectCourtHearings,
            projectCourtCases,
            projects,
            dataProvider
        )

        const mappedEvents = events.map((value, index) => {
            return {
                id: index,
                backColor: !!value.color ? value.color : '#6B6B6B',
                title: value.title,
                link: value.link,
                start: moment(value.startDate).toDate(),
                end: moment(value.endDate).toDate()
            }
        })

        setIsFetched(true)
        setEvents(mappedEvents)
    }

    useEffect(() => {
        if (!isFetched) fetchData()
    })

    return (
        <Paper style={{ padding: '1em', height: '50vw' }}>
            <Calendar
                className={classes.calendar}
                localizer={localizer}
                culture="ru"
                events={events}
                startAccessor="start"
                endAccessor="end"
                messages={calendarMessages}
            />
        </Paper>
    )
}

const mapStateToProps = (state) => ({
    version: state.admin.ui.viewVersion
})

export const CalendarView = compose<any>(
    connect(mapStateToProps, { setTitle: titleAction }),
    withDataProvider,
    withStyles(styles)
)(CalendarComponent)
