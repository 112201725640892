import React from 'react'
import { Toolbar, SaveButton } from 'react-admin'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { saveWithNoteCreate } from '../../utils/dataHelpers'
import handleValueBeforeSubmit from '../../utils/handleValueBeforeSubmit'

const useStyles = makeStyles({
    dBET: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    ToolbarCreate: {
        backgroundColor: 'white'
    }
})

const ToolbarCreateBase = (props) => {
    const classes = useStyles(``)
    const {
        isShow,
        customRedirect,
        disabled,
        basePath,
        handleSubmit,
        saveWithNoteCreate,
        resource,
        shouldParse,
        showCheckBox,
        id,
        afterSubmit,
        ...rest
    } = props

    const handleBeforeSubmit = () =>
        handleSubmit((values) => {
            saveWithNoteCreate(
                handleValueBeforeSubmit({ ...values }, resource),
                basePath,
                resource,
                !!customRedirect ? customRedirect : 'list'
            )
            if (typeof afterSubmit === 'function') afterSubmit()
        })
    debugger
    return (
        <Toolbar {...rest} className={classes.ToolbarCreate}>
            <SaveButton
                handleSubmitWithRedirect={
                    shouldParse ? handleBeforeSubmit : undefined
                }
                disabled={disabled}
                size="small"
                redirect={!!customRedirect ? customRedirect : 'list'}
                variant="flat"
            />
        </Toolbar>
    )
}
export default connect(undefined, { saveWithNoteCreate })(ToolbarCreateBase)
