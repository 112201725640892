import * as React from 'react'
import {
    Avatar,
    Card,
    CardHeader,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    withStyles,
    Typography
} from '@material-ui/core'
import { translate, CREATE } from 'react-admin'
import { compose } from 'recompose'
import { CardIcon } from '../cardIcon'
import { messageContent } from '../../../utils/notifications/notificationItems'
import { Link } from 'react-router-dom'
import { Resources } from '../../../api/api'
import { Mail as CustomerIcon } from '@material-ui/icons'
import oDataProvider from '../../../api/oDataProvider'

const style = (theme) => ({
    root: {
        flex: 1
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right'
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary
    },
    item: {
        '&:active': {
            color: '#5EC3C3'
        }
    }
})

const MyNotificationsComponent = ({ messages = [], translate, classes }) => {
    return (
        <div className={classes.root}>
            <CardIcon Icon={CustomerIcon} bgColor="#4caf50" />
            <Card className={classes.card}>
                <CardHeader title={'Сообщения'} />
                <List dense={true}>
                    {messages.map((record: any, i) => {
                        const { linkTo, titles } = messageContent(record)

                        const readed = () =>
                            oDataProvider(CREATE, Resources.Notification_Read, {
                                data: { key: record.id }
                            })
                        return (
                            <Link
                                key={`li-${record.id}`}
                                style={{ textDecoration: 'none' }}
                                to={linkTo}
                                onClick={readed}
                            >
                                <ListItem
                                    key={`key-${i}`}
                                    button={true}
                                    component="li"
                                    // className={classes.item}
                                    classes={{
                                        button: classes.item
                                    }}
                                >
                                    <div
                                        style={{
                                            color: record.isRead
                                                ? '#E8E8E8'
                                                : '#73C6ED'
                                        }}
                                    >
                                        &#8226;
                                    </div>
                                    <ListItemText
                                        style={{ marginRight: 30 }}
                                        primary={new Date(
                                            record.emailSent
                                        ).toLocaleString('ru-RU')}
                                        secondary={titles}
                                    />
                                    <ListItemSecondaryAction>
                                        <Typography className={classes.cost}>
                                            Система
                                        </Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </Link>
                        )
                    })}
                </List>
            </Card>
        </div>
    )
}

export const MyNotifications = compose(
    withStyles(style as any),
    translate
)(MyNotificationsComponent)
