import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface IRequestModel extends IStandardFieldsModel {
    customerId: 'customerId'
    customer: 'customer'
    subject: 'subject'
    firstName: 'firstName'
    lastName: 'lastName'
    patronymicName: 'patronymicName'
    text: 'text'
    company: 'company'
    email: 'email'
    phone: 'phone'
    other: 'other'
    isExternal: 'isExternal'
    userFilters: 'userFilters'

    existingUserId: 'existingUserId'
    existingUser: 'existingUser'

    projectId: 'projectId'
    project: 'project'

    projectTaskId: 'projectTaskId'
    projectTask: 'projectTask'

    status: 'status'
    rejectionReason: 'rejectionReason'

    commentListId: 'commentListId'
    commentList: 'commentList'

    classification: 'classification'
    timelineEvents: 'timelineEvents'
    versionedFiles: 'VersionedFiles'
}

const request: IRequestModel = {
    ...standardFieldsModel,
    customerId: 'customerId',
    customer: 'customer',
    subject: 'subject',
    firstName: 'firstName',
    lastName: 'lastName',
    patronymicName: 'patronymicName',
    text: 'text',
    company: 'company',
    email: 'email',
    phone: 'phone',
    other: 'other',
    isExternal: 'isExternal',

    userFilters: 'userFilters',
    existingUserId: 'existingUserId',
    existingUser: 'existingUser',

    projectId: 'projectId',
    project: 'project',

    projectTaskId: 'projectTaskId',
    projectTask: 'projectTask',

    status: 'status',
    rejectionReason: 'rejectionReason',

    commentListId: 'commentListId',
    commentList: 'commentList',
    classification: 'classification',
    timelineEvents: 'timelineEvents',
    versionedFiles: 'VersionedFiles'
}

export const requestModel = Object.freeze(request)
